import * as React from 'react'

const A = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="150"
    height="150"
    viewBox="0 0 150 155"
    // preserveAspectRatio="207"
  >
    <g
      transform="translate(0.000000,159.000000) scale(0.100000,-0.100000)"
      fill="#ef4022"
      stroke="#ef4022"
    >
      <path d="M576 1338 c-55 -139 -137 -345 -182 -458 l-82 -205 -101 -3 c-127 -3 -130 -15 -31 -91 38 -29 70 -56 70 -60 0 -4 -27 -73 -59 -155 -56 -141 -62 -152 -125 -215 -42 -42 -66 -75 -66 -89 l0 -22 237 2 c202 3 238 5 241 18 1 8 -33 46 -78 85 -94 81 -92 59 -21 238 l48 122 164 0 c238 0 271 -18 350 -183 28 -57 47 -108 42 -112 -4 -3 -43 -35 -85 -70 -128 -103 -137 -100 257 -100 297 0 336 2 342 16 4 11 -17 39 -66 88 l-72 72 -293 685 -294 684 -48 3 -49 3 -99 -253z m138 -482 c42 -98 75 -181 73 -183 -2 -2 -70 -2 -150 -1 l-145 3 71 182 c39 101 72 182 73 180 1 -1 36 -83 78 -181z" />
    </g>
  </svg>
)

export default A
