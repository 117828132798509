import * as React from 'react'
import { withRouter, Link } from 'react-router-dom'
import A from './Icons/A'

import * as S from '../styles/Header.styles'

export const Nav = withRouter(({ location }) => {
  const isActive = path => (location.pathname === path ? 'active' : '')
  return (
    <S.HeaderStyles>
      <section className="section section-header">
        <div className="section-container">
          <header className="header">
            <div className="logo">
              <div className="logo-wrapper">
                <A />
                <div className="logo-text">
                  <span className="transparent">A</span>ndrew Jarrett
                </div>
              </div>
            </div>
            <div className="navbar">
              <ul>
                <li>
                  <a className={isActive('/')} href="/">
                    Projects
                  </a>
                </li>
                <li>
                  <Link className={isActive('/about')} to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className={isActive('/contact')} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </header>
        </div>
      </section>
    </S.HeaderStyles>
  )
})

const Header = () => (
  <S.HeaderStyles>
    <section className="section section-header-description">
      <div className="section-container">
        <div className="hr" />
        <div className="desktop-header-spacing" />
        <div className="header-description">
          <S.Heading>
          Hi! My name is Andrew 👋
        </S.Heading>

          <br />
          <S.Heading>
            I'm a software engineer. I've programmed applications in Python, Ruby and Clojure, but mostly I write JavaScript until the project needs a little <S.Italics>extra</S.Italics>.


          </S.Heading>
          <br />
          <S.Heading>
For better or worse, JS has become the lingua franca of the nerds.
</S.Heading>

          <br />
          <S.Paragraph>

Mostly for the better, I think. My first exposure was with LISP and Standard ML, so I gravitate toward a functional approach when problem solving -- not out of some academic devotion, but because it's actually all I know, and so far it's been enough. Cool stuff like React, TypeScript and ReasonML make my life a lot easier, and I can't imagine switching to a language that doesn't have first-class functions or lexical scoping.
</S.Paragraph>
          <br />
          <S.Paragraph>
 In fact, I'd take closures and functions-as-data over all the sugar in the world (looking at you, Ruby).         <br />
 </S.Paragraph>
          <br />

          <S.Paragraph>
But really, I'll take it all. Because at the end of the day, I'm just a guy who likes making cool shit and hates when things break.
</S.Paragraph>
          <br />

          <S.Paragraph>
Here's some cool shit I made that's probably not broke 🤞
</S.Paragraph>

          </div>
          <div className="desktop-header-description-spacing" />
        </div>
    </section>
  </S.HeaderStyles>
)

export default Header

// Andrew Jarrett is a software engineer who started out in inside
// sales. He loves a good workflow and sometimes builds them
// professionally, but what excites him most is working on applications
// that are mission-critical for a company he believes in. He lives in
// Austin, TX with his dog Ash Nabisco.
// {/* Andrew Jarrett is a software engineer who came up in inside sales.
// He loves a good workflow and sometimes builds them professionally,
// but what really gets him going is the user. He believes good design
// is intuitive, that great design is invisible, and that sayings
// things that _sound_ meaningful can be a great way to get out of
// feeling any empathy at all. */}
// {/* <h4>If that sounds like what you’ve got, let’s talk.</h4> */}


//My name is Andrew 👋
//
//I’m a software engineer that genuinely loves really likes what he does for a living.
//
//At one point in my career I helped a large, decentralized team incrementally refactor a legacy codebase. I've also worked with "1-pizza" teams where we built everything from scratch. Doing both taught me that I actually know very little when it comes to software.
//
//I think the most valuable thing I've learned is that, when it comes to software at least, knowledge isn't power.
//
//Knowledge is a force. Sometimes getting stuck is a smell. Am I applying the wrong kind of force. Developing an intuition for that is about unlearning. Somewhere is there is the definition of a heuristic, or at least gets at the heart of it, and I'd do better to ask myself more often: is this expertise, or an awkward use for a hammer?
//
//How fucking philosophical.
//
//Anyway I'd never say shit like out loud. Like most things, working with me probably has its tradeoffs. I’m internally motivated by nature. Coworkers have referred to me as a workaholic or a perfectionist, once “the dude who actually read the docs”, but I'd call it just being a guy who likes making cool shit and hates making shit that breaks later.
//
//Here's some cool shit I made that's probably not broken 🤞
